<template id="articulos_proveedor">
<div class="page-articulos_proveedor">

  <v-container fluid fill-height>
    <v-row :justify="'center'" :align="'center'">
      <v-col sm='12'>
        <v-card class="pa-5">

          <v-card-text>
            <div id="datagrid" v-on:keyup.enter="registros.items = []; $refs.pagination_pw.$refs.infiniteLoading.$emit('$InfiniteLoading:reset');">

              <v-client-table ref="vuetable" :data="items" :columns="columns" :options="options" class="elevation-2 pa-2">
                <template slot="noResults">
                  <v-alert :value="true" icon="info" color="info">No se encontró ningún registro</v-alert>
                </template>

                <template slot="total" slot-scope="props">
                  {{props.row.articulos.length}}
                </template>

                <template slot="actions" slot-scope="props">
                  <v-speed-dial v-model="props.row.id" direction="left" open-on-hover >
                    <template v-slot:activator>
                      <v-btn x-small fab dark color="primary">
                        <v-icon v-if="fab[props.row.id]">mdi-close</v-icon>
                        <v-icon v-else>reorder</v-icon>
                      </v-btn>
                    </template>
                    <v-btn x-small fab dark color="blue" @click.native="get_proveedor(props.row._id)" title="Agregar Articulos" v-tippy>
                      <v-icon>edit</v-icon>
                    </v-btn>
                  </v-speed-dial>
                </template>
              </v-client-table>
              <PaginacionComponent ref="pagination_pw" :props="props_paginacion"></PaginacionComponent>
            </div>
          </v-card-text>

        </v-card>
      </v-col>
    </v-row>
  </v-container>

  <v-dialog v-model="modal" max-width="50%">
    <v-card>
      <v-card-title>
        <v-card class="primary white--text titulomodal">
          <v-icon class="white--text">{{ update ? "edit" : "add" }}</v-icon>&nbsp;
          <span class="subheading">
            <strong>Asignar Articulos al Proveedor</strong>
          </span>
        </v-card>
        <v-spacer></v-spacer>
        <v-btn icon @click.native="modal = false" class="close_modal">
          <v-icon class="white--text">cancel</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-form ref="form" lazy-validation>
          <v-container grid-list-md @keyup.enter="actualizar()">
            <v-row>
              <v-card-text>
                  <v-row>
                    <v-col xs="6">
                      <v-text-field text prepend-icon="search" placeholder="Buscar" v-model="search" hide-details class="hidden-sm-and-down"></v-text-field>
                    </v-col>
                  </v-row>
                  <br>
                <v-data-table hide-default-footer :items-per-page="100" :headers="articulos.headers" :search="search" :items="articulos.items" class="elevation-1" v-model="articulos.selected" item-key="_id" show-select>
                  <template slot="no-data">
                    <v-alert :value="true" color="error" icon="warning">
                      No hay datos...
                    </v-alert>
                  </template>

                  <template slot="items" slot-scope="articulos">
                    <tr>
                      <td>
                        <v-checkbox v-model="articulos.selected" primary hide-details></v-checkbox>
                      </td>
                      <td>{{ articulos.item.nombre }}</td>
                      <td>hola</td>
                    </tr>
                  </template>
                </v-data-table>
              </v-card-text>
            </v-row>
          </v-container>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn @click.native="modal = false">
          <v-icon>cancel</v-icon> Cancelar
        </v-btn>
        <v-btn color="success" @click.native="actualizar()">
          <v-icon>done</v-icon> {{ "Guardar" }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>

</div>
</template>


<script>
import PaginacionComponent from '@/components/PaginacionComponent.vue';

export default {
  components: {
    "PaginacionComponent": PaginacionComponent
  },
  watch: {
    model_filters: {
      handler(val) {
        // this.$nextTick(() => {
        //   this.items = [];
        //   this.$refs.pagination_pw.$refs.infiniteLoading.$emit('$InfiniteLoading:reset');
        // });
      },
      deep: true
    }
  },
  mounted: function() {
    this.model_filters = this.$refs.vuetable.$refs.table.query;
  },
  created: function() {
    this.model = this.clean_model();
    this.get_articulos();
    // this.get_proveedores();
  },
  data() {
    return {
      model_filters: "",
      props_paginacion: {
        infiniteHandler: this.infiniteHandler,
        total_registros: 0
      },
      search:"",
      fab: [],
      breadcrumbs: [{
          text: "Inicio",
          disabled: false,
          to: "/index"
        },
        {
          text: "Catálogos",
          disabled: true,
          href: ""
        },
        {
          text: "Articulos por Proveedor",
          disabled: true,
          href: ""
        }
      ],
      columns: [
        "nombre",
        "rfc",
        "total",
        "actions"
      ],
      options: {
        filterable: ["nombre", "rfc", "total"],
        sortable: ["nombre", "rfc", "total"],

        headings: {
          nombre: "Nombre",
          rfc: "RFC",
          total: "Total de Articulos",
          actions: "Operaciones"
        },
      },
      articulos: {
        selected: [],
        headers: [{
            text: "Nombre",
            value: "nombre",
            width: "30%"
          },
          {
            text: "Marca",
            value: "marca.nombre",
            width: "60%"
          }
        ],
        items: [0]
      },
      modal: false,
      update: false,
      model: {},
      rules: {
        required: v => !!v || "Este campo es requerido",
        decimal: value => {
          const pattern = /^[\d]*(\.{0,1}[\d]*)$/;
          return pattern.test(value) || "Número decimal no válido";
        },
        entero: function(numero) {
          const pattern = /^[\d]*$/;
          return pattern.test(numero) || "Debe ser un número entero";
        },
      },
      items: []
    }
  },
  methods: {
    infiniteHandler: function($state) {
      var rows_per_page = 10;
      var page = Math.ceil(this.items.length / rows_per_page); // == 1
      var skip = page * rows_per_page;
      let where = {};

      this.options.filterable.forEach(x => {
        if (this.model_filters[x]) {
          where[x] = {
            "$regex": "(?i)" + this.model_filters[x].toString().replace("(","\\(").replace(")","\\)").replace("+","\\+")
          }
        }
      });


      axios({
          method: 'POST',
          url: process.env.VUE_APP_COUCHDB_URL + '/proveedores/_find/',
          withCredentials: true,
          data: {
            limit: rows_per_page,
            skip: skip,
            selector: where
          },
        }).then(response => {
          if (response.data.docs.length) {
            var unique_records = new Set(this.items.map(item => item._id));
            unique_records = Array.from(unique_records.values());            
            var nuevos = response.data.docs.filter(e => !unique_records.includes(e._id));
            this.items = this.items.concat(nuevos);
            this.props_paginacion.total_registros = this.items.length;
            $state.loaded();
          } else {
            $state.complete();
          }
        })
        .catch(error => {
          console.log(error)
        })
    },
    clean_model: function() {
      return {
        _id: "",
        _rev: "",
        nombre: "",
        rfc: "",
        telefono_1: "",
        telefono_2: "",
        fax: "",
        contacto: "",
        giro: "",
        email: "",
        domicilio: "",
        numero_exterior: "",
        numero_interior: "",
        colonia: "",
        ciudad: "",
        referencias: "",
        codigo_postal: "",
        fecha_alta: window.moment(window.moment().format("YYYY-MM-DDTHH:mm:ss")).format("DD-MM-YYYY"),
        estatus: "Activo",
        articulos: []
      };
    },
    get_articulos: function() {

      window.dialogLoader.show('Espere un momento por favor..');

      let data = {
        "selector": {},
        "fields": ["_id", "nombre", "marca.nombre"],
        "sort": ["codigo_barras"],
      };

      window.axios
        .post(process.env.VUE_APP_COUCHDB_URL + '/articulos/_find/', data)
        .then(response => {
          if (response.data.docs.length > 0) {
            this.articulos.items = response.data.docs;
          } else
            this.articulos.items = [];
          window.dialogLoader.hide();
        })
        .catch(error => {
          this.$swal({
            type: "error",
            title: "¡Operación no Permitida!",
            text: "Ocurrió un error al obtener los registros.",
            footer: ""
          });
          window.dialogLoader.hide();
        });
    },
    get_proveedores: function() {
      window.dialogLoader.show('Espere un momento por favor..');

      let data = {
        "selector": {},
        "sort": ["rfc"],
      };

      window.axios
        .post(process.env.VUE_APP_COUCHDB_URL + '/proveedores/_find/', data)
        .then(response => {
          this.items = response.data.docs;
          window.dialogLoader.hide();
        })
        .catch(error => {
          this.$swal({
            type: "error",
            title: "¡Operación no Permitida!",
            text: "Ocurrió un error al obtener los registros.",
            footer: ""
          });
          window.dialogLoader.hide();
        });
    },
    get_proveedor: function(id) {
      window.axios
        .get(process.env.VUE_APP_COUCHDB_URL + "/proveedores/" + id)
        .then(response => {
          this.model = response.data;
          this.editar();
        })
        .catch(error => {

          this.$swal({
            type: "error",
            title: "¡Operación no Permitida!",
            text: "Ocurrió un error al obtener el registro",
            footer: ""
          });
        });
    },
    editar: function() {
      this.update = true;
      this.modal = true;
      this.articulos.selected = this.model.articulos;
      this.search = "";
    },
    actualizar: function() {
      if (this.$refs.form.validate()) {
        this.model.articulos = this.articulos.selected;
        let data = this.model;
        window.dialogLoader.show('Espere un momento por favor..');
        window.axios
          .put(process.env.VUE_APP_COUCHDB_URL + '/proveedores/' + this.model._id + '?conflicts=true', data)
          .then(response => {
            window.dialogLoader.showSnackbar('El registro se actualizó correctamente..', {
              color: 'success'
            });
            this.items = [];
            this.$refs.pagination_pw.$refs.infiniteLoading.$emit('$InfiniteLoading:reset');
          })
          .catch(error => {
            window.dialogLoader.showSnackbar('Ocurrió un error al actualizar el registro..', {
              color: 'error'
            });
          }).then(() => {
            this.modal = false;
            window.dialogLoader.hide();
          });
      }
    },
  }
}
</script>
